/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import AllViolationPageDetailStyled from './styledComponent'
import ListBoardWrapper from '../../components/ListBoardWrapper/Loadable'
import DateFilterComponent from '../../components/DateFilterComponent/Loadable'
import DatePickerComponent from '../../components/DatePickerComponent/Loadable'
import Dropdown from '../../components/Dropdown/Loadable'
import CameraDetailCard from '../../components/CameraDetailCard/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'

import * as AllViolationDetailPageActions from './actions'
import * as ApplicationActions from '../ApplicationLayout/actions'
import * as ViolationDetailActions from '../ViolationDetailPage/actions'
import * as NotificationActions from '../NotificationSidebar/actions'

import fetchErrorHandler from '../../utils/fetchErrorHandler'

import {
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_FAILURE,
  FRONTEND_ROUTES,
  THAI_LANGUAGE,
  SORT_TYPE_NAME,
  SORT_TYPE_DESC,
  SORT_TYPE_AREA_NAME,
  SORT_TYPE_ASC,
  SORT_TYPE_CAMERA_ID,
  SORT_TYPE_CAMERA_STATUS
} from '../../utils'
import MESSAGE from './message'

const LIST_COLOR_BARS = [
  {
    key: 'Normal Activity',
    color: '#4a90e2'
  },
  {
    key: 'Violation',
    color: '#f16745'
  }
]
let timing

export class AllViolationDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cameraSubLeftBarMenu: [],
      cameraDetailFilterBy: { text: '-- Select Sort By --', value: '' },
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    }
    this.abortController = new AbortController()
  }

  componentDidMount() {
    this._loadAllDetail()
    this.initialIntervalLoadData()
  }

  componentWillUnmount() {
    clearInterval(timing)
  }

  _loadAllDetail() {
    this.abortController.abort()
    this.abortController = new AbortController()
    const siteId = this.props.match.params.siteId
    const day = this.getDayRequest()
    this.props.loadAllViolationDetail(day, this.props.dateFilterType, siteId, this.abortController.signal)
  }

  initialIntervalLoadData() {
    timing = setInterval(() => {
      const { dateFilterType } = this.props
      const day = this.getDayRequest()
      this._syncCameraList()
      this.props.syncAllViolationDetail(day, dateFilterType, this.props.match.params.siteId)
    }, process.env.INTERVAL_CALL_API_MINUTES * 60 * 1000)
  }

  _syncCameraList() {
    const siteId = this.props.match.params.siteId
    this.props.syncCameraList(siteId)
    this.props.getListNotification(siteId)
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleFetchLoadAllViolation(prevProps)
    this.handleSyncAllViolation(prevProps)
  }

  handleFetchLoadAllViolation(prevProps) {
    if (prevProps.loadAllViolationStatus === FETCH_STATUS_REQUEST && this.props.loadAllViolationStatus === FETCH_STATUS_FAILURE) {
      this.handleDefaultFailure(this.props.loadAllViolationError.status)
    }
  }

  handleSyncAllViolation(prevProps) {
    if (prevProps.syncAllViolation === FETCH_STATUS_REQUEST && this.props.syncAllViolation === FETCH_STATUS_FAILURE) {
      this.handleDefaultFailure(this.props.syncAllViolationError.status)
    }
  }

  handleDefaultFailure(statusCode) {
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }
  getDayRequest() {
    let day = this.props.date.format('YYYY-MM-DD')
    if (this.props.dateFilterType === 'week') {
      day = moment(this.props.date).startOf('week').format('YYYY-MM-DD')
    }
    return day
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  onDateChange = async (date) => {
    await this.props.updateDatePicker(date)
    this._loadAllDetail()
  }

  onDayFilterchange = async (type) => {
    await this.props.updateDateFilter(type)
    this._loadAllDetail()
  }

  cameraDetailFilterByChange = (type) => {
    this.setState({
      cameraDetailFilterBy: type
    })
    this.handleTypeChange(type.value)
  }

  handleTypeChange(type) {
    switch (type) {
      case SORT_TYPE_NAME:
        this.handleSortByname()
        break
      case SORT_TYPE_CAMERA_STATUS:
        this.handleSortByStatus()
        break
      case SORT_TYPE_DESC:
        this.handleSortByViolationDesc()
        break
      case SORT_TYPE_ASC:
        this.handleSortByViolationAsc()
        break
      case SORT_TYPE_CAMERA_ID:
        this.handleSortByCameraID()
        break
      case SORT_TYPE_AREA_NAME:
        this.handleSortByArea()
        break
      default:
    }
  }

  handleSortByname() {
    const compare = (a, b) => {
      if (a.camera.name < b.camera.name) {
        return -1
      }
      if (a.camera.name > b.camera.name) {
        return 1
      }
      return 0
    }
    this.props.allCameraDetails.sort(compare)
  }

  handleSortByStatus() {
    const compare = (a, b) => {
      if (a.camera.camera_status.toUpperCase() < b.camera.camera_status.toUpperCase()) {
        return -1
      }
      if (a.camera.camera_status.toUpperCase() > b.camera.camera_status.toUpperCase()) {
        return 1
      }
      return 0
    }
    this.props.allCameraDetails.sort(compare)
  }

  handleSortByViolationDesc() {
    const compare = (a, b) => {
      if (a.violation_count < b.violation_count) {
        return 1
      }
      if (a.violation_count > b.violation_count) {
        return -1
      }
      return 0
    }
    this.props.allCameraDetails.sort(compare)
  }

  handleSortByViolationAsc() {
    const compare = (a, b) => {
      if (a.violation_count < b.violation_count) {
        return -1
      }
      if (a.violation_count > b.violation_count) {
        return 1
      }
      return 0
    }
    this.props.allCameraDetails.sort(compare)
  }

  handleSortByCameraID() {
    const compare = (a, b) => {
      if (a.camera.camera_id < b.camera.camera_id) {
        return -1
      }
      if (a.camera.camera_id > b.camera.camera_id) {
        return 1
      }
      return 0
    }
    this.props.allCameraDetails.sort(compare)
  }

  handleSortByArea() {
    const compare = (a, b) => {
      if (a.area_name < b.area_name) {
        return -1
      }
      if (a.area_name > b.area_name) {
        return 1
      }
      return 0
    }
    this.props.allCameraDetails.sort(compare)
  }

  getFilters() {
    const dropdownOptionsOneArea = [
      { text: this.getMessage('default_option'), value: '' },
      { text: this.getMessage('camera_name_option'), value: 'name' },
      { text: this.getMessage('status_option'), value: 'camera_status' },
      { text: this.getMessage('violation_high_option'), value: 'desc' },
      { text: this.getMessage('violation_low_option'), value: 'asc' },
      { text: this.getMessage('cam_id_option'), value: 'camera_id' }
    ]

    const dropdownOptions = [
      { text: this.getMessage('default_option'), value: '' },
      { text: this.getMessage('camera_name_option'), value: 'name' },
      { text: this.getMessage('status_option'), value: 'camera_status' },
      { text: this.getMessage('violation_high_option'), value: 'desc' },
      { text: this.getMessage('violation_low_option'), value: 'asc' },
      { text: this.getMessage('area_option'), value: 'area_name' }
    ]

    return (
      <div className="filter-wrapper">
        <div className="date-picker-container">
          <DatePickerComponent value={this.props.date} onDateChange={this.onDateChange} type={this.props.dateFilterType} />
        </div>
        <div className="date-filter-container">
          <DateFilterComponent
            value={this.props.dateFilterType}
            onFilterChanged={this.onDayFilterchange}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
          />
        </div>
        <div className="dropdown-container">
          <Dropdown
            options={this.props.areaCount === 1 ? dropdownOptionsOneArea : dropdownOptions}
            label={this.getMessage('sort_by_label')}
            onChange={this.cameraDetailFilterByChange}
            value={this.state.cameraDetailFilterBy}
            language={this.props.currentUser?.language || THAI_LANGUAGE}
            labelPosition="left"
            buttonColor="#27a448"
          />
        </div>
      </div>
    )
  }

  getListGraphDetail(arrayData) {
    if (arrayData.length === 0) {
      return []
    }
    const sampleData = arrayData.reduce((prev, current) => {
      return Object.assign({}, prev, current)
    }, {})
    delete sampleData['label']
    const listGraphDetail = Object.keys(sampleData).map((key, index) => {
      const colorData = LIST_COLOR_BARS.find((data) => data.key === key)
      return {
        name: key,
        color: colorData ? colorData.color : '#f5a623'
      }
    })
    return listGraphDetail
  }

  getAllCameraDetail() {
    const { allCameraDetails } = this.props
    let output = allCameraDetails.map((data) => {
      return (
        <CameraDetailCard
          key={data.camera.camera_id}
          data={data}
          listBars={this.getListGraphDetail(data.activity_graph)}
          language={this.props.currentUser?.language || THAI_LANGUAGE}
          oneArea={this.props.areaCount === 1}
        />
      )
    })
    return <div className="camera-detail-deck">{output}</div>
  }

  getContent() {
    let output = <CameraDetailCard data={{}} isLoading={true} />
    if (this.props.loadAllViolationStatus !== FETCH_STATUS_REQUEST) {
      output = this.getAllCameraDetail()
    }
    return output
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  render() {
    return (
      <AllViolationPageDetailStyled>
        <ListBoardWrapper title={this.getMessage('camera_detail_header')} filter={this.getFilters()}>
          {this.getContent()}
        </ListBoardWrapper>
        {this.getFailureModal()}
      </AllViolationPageDetailStyled>
    )
  }
}

/*istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    allCameraDetails: state.allViolationDetailPage.allCameraDetails,
    loadAllViolationStatus: state.allViolationDetailPage.loadAllCameraDetail.fetchStatus,
    loadAllViolationError: state.allViolationDetailPage.loadAllCameraDetail.error,
    syncAllViolation: state.allViolationDetailPage.loadAllCameraDetail.syncStatus,
    syncAllViolationError: state.allViolationDetailPage.loadAllCameraDetail.error,
    areaCount: state.allViolationDetailPage.areaCount,
    date: state.applicationPage.date,
    dateFilterType: state.applicationPage.dateFilterType,
    currentUser: state.loginPage.loginAPI.userData
  }
}

/*istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, ViolationDetailActions, AllViolationDetailPageActions, NotificationActions, ApplicationActions),
    dispatch
  )
}

AllViolationDetailPage.propTypes = {
  history: PropTypes.object,
  allCameraDetails: PropTypes.array,
  loadAllViolationStatus: PropTypes.string,
  loadAllViolationDetail: PropTypes.func,
  syncAllViolationDetail: PropTypes.func,
  syncAllViolation: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    language: PropTypes.string
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(AllViolationDetailPage)
