import styled from 'styled-components'

export default styled.div`
  .filter-wrapper {
    display: flex;
    align-items: center;
    .date-picker-container,
    .date-filter-container {
      margin-right: 20px;
    }
  }
  .camera-detail-deck {
    display: flex;
    flex-direction: column;
    .camera-detail-card {
      margin-bottom: 20px;
      height: 100%;
    }
  }
`
