const MESSAGE = {
  EN: {
    camera_detail_header: 'Camera Details',
    normal_activity_legend: 'Normal Activity',
    violation_activity_legend: 'Violation',
    sort_by_label: 'Sort by',
    default_option: '-- Select Sort By --',
    camera_name_option: 'Camera Name',
    status_option: 'Status',
    violation_high_option: 'Violation Count - High',
    violation_low_option: 'Violation Count - Low',
    cam_id_option: 'Camera ID',
    area_option: 'Area',
    error_dialog_title_default: 'Something went wrong',
    error_dialog_message_default: `There was a problem connection to the server. 
    Please try again or contact support.`,
    try_again_button: 'Try again'
  },
  TH: {
    camera_detail_header: 'รายละเอียดกล้อง',
    normal_activity_legend: 'กิจกรรมปกติ',
    violation_activity_legend: 'การฝ่าฝืน',
    sort_by_label: 'จัดเรียงตาม',
    default_option: '-- เลือกการจัดเรียง --',
    camera_name_option: 'ชื่อกล้อง',
    status_option: 'สถานะ',
    violation_high_option: 'จำนวนการฝ่าฝืนมากที่สุด',
    violation_low_option: 'จำนวนการฝ่าฝืนน้อยที่สุด',
    cam_id_option: 'ID กล้อง',
    area_option: 'พื้นที่',
    error_dialog_title_default: 'ข้อผิดพลาด',
    error_dialog_message_default: `เกิดปัญหาในการเชื่อมต่อกับเซิร์ฟเวอร์
    กรุณาลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน`,
    try_again_button: 'ลองอีกครั้ง'
  }
}

export default MESSAGE
